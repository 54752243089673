// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'num_segments',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'shop',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'phone_number',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'sms_type',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'created_at',
    label: '',
    Object: 'value',
    width: '120'
  }]
}
